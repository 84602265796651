@import "./src/styles/global/_index.scss";
.ContextMenu {
  padding: 5px 0;

  border: 1px solid #dadada;
  background-color: #fff;

  box-shadow: 0 0 3px #ddd;

  border-radius: 3px;

  z-index: 99;

  &Item {
    padding: 7px 20px;

    font-size: 14px;
    font-weight: 300;
    color: #222;
    @include font-smoothing(off);

    &:hover {
      background-color: #eee;
    }
  }
}
