.react-contextmenu {
  visibility: hidden;

  opacity: 0 !important;

  transition: none;

  &--visible {
    visibility: visible;

    opacity: 1 !important;

    transition: opacity 0.1s ease;
  }
}
