@import "./src/styles/global/_index.scss";
.Container {
  width: 600px;
  max-height: 250px;
}

.Content {
  display: flex;
  flex-direction: column;
}

.Label {
  font-size: 14px;
  color: #257985;
  line-height: 1.35;
}

.Field {
  &Container {
    margin-top: 10px;

    background-color: #fff;
  }

  box-sizing: border-box;

  width: 100%;

  padding: 8px 13px;

  border: 1px solid #e5e5e5;
  background: none;

  font-size: 16px;
}

.Buttons {
  text-align: right;
}
