@import "./src/styles/global/_index.scss";
.Container {
  width: 600px;
  max-height: 515px;
}

.Content {
  display: flex;
  flex-direction: column;
}

.Description {
  margin: 0 15px 20px;

  color: $link-color;
}

.Form {
  display: flex;
  flex-direction: column;

  flex: 1;

  padding: 10px 15px 20px;

  background-color: #fff;

  &Title {
    margin-bottom: 10px;

    font-weight: 700;
    color: $link-color;
  }
  &RadioButtons {
    &Label {
      padding-left: 25px;

      color: $link-color;
      font-weight: 400;
    }
    &ItemActive &Label {
      font-weight: 700;
    }
  }
}

.Buttons {
  text-align: right;

  &Item {
    width: 105px;
  }
}
