// Base
$base-color: #000;
$font-size: 14px;
$background-color: #f2f2f2;
$link-color: #33828e;
$link-active-color: #f1682a;

// Header
$header-height: 55px;

// Content
$content-max-width: 1280px;
$content-horizontal-offset: 20px;
$content-max-width-without-offset: $content-max-width - ($content-horizontal-offset * 2);

