@import "./src/styles/global/_index.scss";
.Button {
  min-width: 110px;

  padding: 10px 12px;

  border: 1px solid $link-color;
  background: $link-color;

  font-size: 16px;
  font-weight: 300;
  color: #fff;
  line-height: 1;
  @include font-smoothing(off);

  border-radius: 3px;

  cursor: pointer;

  & + & {
    margin-left: 15px;
  }
  &:disabled {
    opacity: 0.5;

    cursor: default;
  }
  &Transparent {
    background-color: transparent;

    font-weight: 400;
    color: $link-color;
    @include font-smoothing(off);
  }
  &Icon {
    &:before {
      float: left;

      margin: -2px 5px -2px -2px;

      font-size: 20px;
    }
  }
  &Small {
    min-width: 0;

    padding: 5px 6px;

    font-size: 14px;

    &:before {
      margin: -1px 5px -1px -2px;

      font-size: 16px;
    }
  }
  &WithoutChildren {
    &:before {
      margin-right: -2px;
    }
  }
}
