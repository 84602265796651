:global {
  html,
  body {
    min-width: 1280px;

    background: $background-color;

    @include open-sans;
    font-size: $font-size;
    color: #000;

    @include font-smoothing;
    -ms-overflow-style: scrollbar;
  }

  body {
    position: relative;
  }

  a {
    color: $link-color;
    text-decoration: none;
  }

  strong {
    font-weight: 600;
  }

  input,
  button,
  textarea {
    @include open-sans;
    font-size: $font-size;
  }

  select::-ms-expand {
    display: none;
  }

  video {
    display: block;
  }

  .page {
    height: 100%;
  }

  h1 {
    font-size: 38px;
    font-weight: 300;
  }

  .content {
    max-width: $content-max-width-without-offset;

    margin: 0 auto;

    &-full-height {
      height: 100%;
    }
  }
}
