@import "~react-datepicker/src/stylesheets/datepicker-cssmodules";

:global {
  .react-datepicker__month-read-view--selected-month,
  .react-datepicker__year-read-view--selected-year {
    display: inline-block;

    padding-top: 4px;
  }
}
