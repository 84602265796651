@import "./src/styles/global/_index.scss";
.Content {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  height: 100%;
}

.Main {
  flex: 1 1 auto;
  display: flex;

  overflow: hidden;
  position: relative;

  & > * {
    flex: 1;
  }
}
