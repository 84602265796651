@import "./src/styles/global/_index.scss";
$filter-first-column-width: 290px;
$filter-border-color: #e5e5e5;

.Container {
  position: relative;

  height: 90%;

  background-color: $background-color;

  box-shadow: 0 0 30px #aaa;
}

.Content {
  box-sizing: border-box;

  display: flex;
  flex: 1;
  flex-direction: column;

  height: 100%;
}

.Header {
  padding: 15px 0;

  &Buttons {
    float: right;
  }
  &Categories {
    list-style: none;

    overflow: hidden;

    &Item {
      float: left;

      padding: 10px 12px;

      border: 1px solid #00a6c0;

      font-size: 16px;
      color: #00a6c0;

      user-select: none;

      cursor: pointer;

      & + & {
        border-left: none;
      }
      &:first-child {
        border-radius: 3px 0 0 3px;
      }
      &:last-child {
        border-radius: 0 3px 3px 0;
      }
      &Selected {
        background-color: #00a6c0;

        color: #fff;

        cursor: default;
      }
      &Disabled {
        color: #ccc;

        cursor: default;
      }
    }
  }
}

.Form {
  flex: 1;

  overflow: hidden;
  position: relative;

  height: 100%;

  background-color: #fff;

  border-radius: 3px 3px 0 0;

  box-shadow: 0 0 3px #ddd;

  &Header {
    overflow: hidden;

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 40px;

    border-bottom: 1px solid $filter-border-color;

    &Search {
      float: left;

      position: relative;

      width: $filter-first-column-width;
      height: 100%;

      border-right: 1px solid $filter-border-color;

      &:before {
        position: absolute;
        top: 9px;
        left: 10px;

        font-size: 24px;
        color: $link-color;

        transform: rotate(90deg);
      }
      &Field {
        box-sizing: border-box;

        position: relative;

        width: 100%;
        height: 100%;

        padding: 5px 25px 5px 40px;

        border: none;
        background: none;

        font-size: 15px;

        &::placeholder {
          opacity: 0.5;
        }
      }
      &ResetBtn {
        display: none;

        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;

        width: 25px;

        padding-top: 10px;

        text-align: center;

        cursor: pointer;

        &:before {
          font-size: 20px;
        }
        &Visible {
          display: block;
        }
      }
    }
    &Button {
      margin: 7px 10px;
    }
    &Right {
      float: right;

      box-sizing: border-box;

      height: 100%;

      padding: 8px 15px 7px;

      text-align: right;

      & + & {
        border-right: 1px solid $filter-border-color;
      }
      &Title {
        float: left;

        margin-top: 5px;

        color: #a2a2a2;
      }
      &Dropdown {
        margin-left: 7px;

        &CurrentValue {
          font-weight: 300;
          @include font-smoothing(off);
        }
      }
    }
    &FormulaMode {
      float: left;

      box-sizing: border-box;

      height: 100%;

      padding: 8px 15px 7px;

      &Label {
        float: left;

        padding: 6px 20px 6px 0;

        font-size: 14px;
        color: #888;
      }
      &List {
        list-style: none;

        overflow: hidden;

        display: inline-block;
      }
      &Item {
        float: left;

        padding: 5px 10px;

        border: 1px solid #e5e5e5;

        font-size: 14px;
        color: #ffaa61;
        text-transform: uppercase;

        user-select: none;

        cursor: pointer;

        &:first-child {
          color: #00a6c0;
        }
        & + & {
          border-left: none;
        }
        &Disabled {
          border-color: #ddd;

          color: #aaa;

          cursor: default;
        }
        &Selected {
          background-color: #ffaa61;

          color: #fff;
        }
        &Selected:first-child {
          background-color: #00a6c0;

          color: #fff;
        }
      }
    }
  }
  &Content {
    box-sizing: border-box;

    height: 100%;

    padding-top: 40px;
  }
  &Groups {
    position: absolute;
    top: 40px;
    left: 0;
    bottom: 0;

    width: $filter-first-column-width;

    border-right: 1px solid $filter-border-color;
  }
  &SubContent {
    display: flex;
    flex: 1;
    flex-direction: column;

    height: 100%;

    margin-left: 291px;
  }
  &Formula {
    display: flex;
    flex-direction: column;

    position: relative;

    height: 210px;

    border-bottom: 1px solid $filter-border-color;

    &Base {
      min-height: 250px;
    }
    &Header {
      flex: 0 0 auto;

      height: 35px;
    }
    &Buttons {
      position: absolute;
      top: 0;
      right: 0;

      z-index: 3;
    }
    &SaveBtn {
      float: right;

      margin: 10px 15px 10px 0;

      &:before {
        float: left;
      }
    }
    &ClearBtn,
    &CancelBtn {
      float: right;

      margin: 10px 15px;

      &:before {
        float: left;
      }
    }
    &Title {
      padding: 10px 15px 0;

      &Label {
        font-weight: 300;
      }
      &Field {
        width: 450px;

        margin-left: 10px;
        padding: 1px 5px;

        border: 1px solid $filter-border-color;
        background: none;
      }
    }
    &Tabs {
      flex: 0 0 auto;

      position: relative;

      height: 30px;

      padding: 0 15px 5px;

      background-color: #fafafa;

      &:before {
        content: '';

        position: absolute;
        top: 0;
        left: 0;
        right: 0;

        height: 1px;

        background-color: $filter-border-color;
      }
      &Item {
        position: relative;

        margin-top: 1px;
        padding-top: 4px;

        border-color: $filter-border-color;
        border-top: none;
        background-color: #fff;

        font-size: 15px;
        color: #555;
        text-transform: uppercase;

        &:first-child {
          border-radius: 0 0 0 3px;
        }
        &:last-child {
          border-radius: 0 0 3px 0;
        }
        &Selected {
          margin-top: 0;
          padding-top: 5px;

          background-color: #fff;

          box-shadow: 0 0 1px #aaa;

          z-index: 1;
        }
      }
    }
  }
  &SubLists {
    overflow: hidden;
    position: relative;

    flex: 1;
  }
  &Dimensions {
    overflow: hidden;

    height: 100%;

    &Half {
      width: 400px;

      border-right: 1px solid $filter-border-color;
    }
  }
  &TargetAudience {
    position: absolute;
    top: 0;
    left: 401px;
    right: 0;
    bottom: 0;
  }
}
