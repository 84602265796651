@import "./src/styles/global/_index.scss";
.Container {
  height: 100%;
}

.List {
  list-style: none;

  display: inline-block;

  padding: 0 15px;

  user-select: none;

  @include clearfix;
}

.Item {
  float: left;

  position: relative;

  min-width: 20px;
  height: 16px;

  padding: 5px 8px;

  border: 1px solid #e5e5e5;

  font-size: 15px;
  font-weight: 300;
  text-align: center;
  color: #000;

  cursor: pointer;

  &:hover {
    background-color: rgba(253, 236, 229, 0.4);
  }
  & + & {
    border-left: none;
  }
  &Selected,
  &Selected:hover {
    background-color: #feffd7;
  }
  &Disabled,
  &Disabled:hover {
    background-color: #fff;

    color: #aaa;

    cursor: default;
  }
}

.Hint {
  position: absolute;
  left: 50%;
  bottom: 100%;

  margin-bottom: 8px;
  padding: 8px 21px;

  background-color: #222;

  font-size: 13px;
  color: #fff;
  white-space: nowrap;
  @include font-smoothing(off);

  opacity: 0;

  transform: translateX(-50%);
  transition: opacity 0.3s ease-out;

  border-radius: 3px;

  pointer-events: none;

  z-index: 999;

  &:after {
    content: '';

    position: absolute;
    left: 50%;
    bottom: -8px;

    width: 0;
    height: 0;

    margin-left: -10px;

    border-top: 8px solid #222;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
}

.Item:hover .Hint {
  opacity: 0.9;
}
