@import "./src/styles/global/_index.scss";
.Title {
  margin-top: 50px;

  font-size: 28px;
  text-align: center;
}

.Table {
  width: 600px;

  margin: 50px auto 30px;
  padding: 20px 10px;

  background-color: #fff;

  border-radius: 3px;

  box-shadow: 0 0 2px #1d5d90;
}

.Category {
  padding: 20px 10px;

  font-size: 22px;
  font-weight: 500;
  color: #000;
  text-align: center;
}

.Row {
  padding: 10px 10px;

  @include clearfix;

  & + & {
    border-top: 1px solid #ccc;
  }
}

.Label {
  float: left;

  box-sizing: border-box;

  width: 50%;

  padding-right: 10px;

  text-align: right;

  &:after {
    content: ':';
  }
}

.Value {
  float: left;

  box-sizing: border-box;

  width: 50%;

  padding-left: 10px;
}
