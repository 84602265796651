@import "./src/styles/global/_index.scss";
.List {
  list-style: none;

  user-select: none;

  &:before,
  &:after {
    content: '';

    display: block;

    height: 10px;
  }
}

.Item {
  overflow: hidden;

  position: relative;

  padding: 10px 15px;

  font-size: 16px;
  font-weight: 300;
  @include font-smoothing(off);
  text-overflow: ellipsis;
  white-space: nowrap;

  cursor: pointer;

  &:hover {
    background-color: rgba(253, 236, 229, 0.4);
  }
  &CanDrag {
    padding-right: 45px;
  }
  &Selected {
    &,
    &:hover {
      background-color: #ffeee7;
    }
  }
  &Saved {
    padding-right: 75px;
  }
  &Saved.ItemCanDrag {
    padding-right: 105px;
  }
}

.Buttons {
  position: absolute;
  top: 5px;
  right: 15px;

  &Item {
    float: right;

    & + & {
      margin-left: 0;
      margin-right: 5px;
    }
    &AddBtn {
      padding: 3px 4px;

      &:before {
        font-size: 20px;
      }
    }
  }
}
