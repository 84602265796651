@import "./src/styles/global/_index.scss";
$table-border-color: #e5e5e5;

.Container {
  flex: 1;

  overflow: hidden;
  position: relative;

  &:after {
    content: '';

    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 0;

    background-color: rgba(0, 0, 0, 0.1);

    transition: none;
  }
  &Disabled:after {
    visibility: visible;

    opacity: 1;

    transition: all 0.1s ease-in-out;
  }
}

.SubContainer {
  display: flex;

  flex: 1;
  flex-direction: column;
}

.Header {
  padding-top: 30px;

  &Buttons {
    float: right;

    padding-top: 30px;
  }
  &Catalog {
    font-size: 18px;
    font-weight: 300;
    color: #8b8b8b;
    @include font-smoothing(off);

    &Title {
      margin-left: 10px;

      font-weight: 600;
      color: #000;
    }
  }
  &Title {
    margin-top: 10px;

    &Text {
      overflow: hidden;

      display: inline-block;

      max-width: 650px;

      margin-bottom: -10px;
      padding-bottom: 10px;

      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &Btn {
      display: inline-block;
      position: relative;
      top: -15px;

      width: 20px;
      height: 17px;

      margin-left: 5px;
      padding-top: 3px;

      border: 1px solid transparent;
      background-color: #fff;

      border-radius: 50%;

      font-size: 14px;
      color: $link-color;
      text-align: center;

      transition: border 0.2s ease, color 0.2s ease;

      cursor: pointer;

      &:hover {
        border-color: #33828e;

        box-shadow: 0 0 5px #ccc;
      }
      &Delete:hover {
        border-color: #f5838d;

        color: #f00;
      }
    }
  }
}

.Content {
  flex: 1;

  display: flex;
  flex-direction: column;

  margin-top: 30px;

  background-color: #fff;

  box-shadow: 0 0 3px #ddd;

  border-radius: 3px 3px 0 0;

  &Header {
    flex: 0 0 auto;

    position: relative;

    height: 40px;

    border-bottom: 1px solid $table-border-color;

    z-index: 2;

    @include clearfix;

    &Column {
      float: left;

      height: 20px;

      padding: 10px 15px;

      border-right: 1px solid $table-border-color;

      &:last-child {
        border-right: none;
      }
      &Right {
        float: right;
      }
      &Label {
        float: left;

        margin-top: 2px;

        font-size: 15px;
        font-weight: 300;
        color: #8b8b8b;
        @include font-smoothing(off);
      }
    }
    &Statistics {
      clear: both;

      list-style: none;

      overflow: hidden;

      padding-top: 5px;
      padding-bottom: 20px;

      &Item {
        float: left;

        box-sizing: border-box;

        width: 50%;

        padding-top: 15px;

        &Affinity {
          display: flex;
          flex-direction: row;

          float: none;

          width: auto;
        }
      }
      &AffinityCheckbox {
        width: 50%;
      }
      &AffinityCount {
        width: 50%;

        padding-top: 2px;
      }
      &Title {
        padding: 6px 0 5px;

        font-size: 16px;
        font-weight: 300;
        color: #8b8b8b;
        text-align: center;
        @include font-smoothing(off);
      }
    }
    &Dropdown {
      margin-top: -1px;
      margin-left: 10px;

      &Value {
        max-width: 140px;

        font-weight: 300;
      }
    }
    &DropPanel {
      &DateTitle {
        max-width: 290px;
      }
      &Wrapper {
        min-width: 100%;

        margin-left: -16px;
        margin-top: 10px;

        border-top: none;
        border-color: $table-border-color;

        border-radius: 0 0 3px 3px;

        &DateRange {
          min-width: calc(100% - 16px);
        }
      }
      &Opened &WrapperMeasure &Content {
        display: flex;
        flex-direction: row;

        width: 900px;
      }
      &Column {
        position: relative;

        width: 450px;

        & + & {
          &:before {
            content: '';

            position: absolute;
            top: -15px;
            left: 0;
            bottom: -15px;

            width: 1px;

            background-color: #dadada;
          }
        }
        &Affinity {
          flex: 0 0 auto;

          width: 450px;

          padding-left: 15px;
          padding-top: 37px;
        }
      }
      &Row {
        overflow: hidden;

        @include clearfix;

        & + & {
          margin-top: 10px;
        }
      }
      &Label {
        float: left;

        margin-top: 5px;
        margin-right: 15px;

        font-size: 15px;
        font-weight: 300;
        color: #8b8b8b;
        @include font-smoothing(off);

        &Icon {
          margin-left: 5px;

          font-size: 16px;
          color: #257985;
        }
        &Tooltip {
          width: 400px;
        }
      }
      &Value {
        float: right;
      }
      &TabsItem {
        font-size: 13px;
      }
      &Checkbox {
        margin-top: 12px;
        margin-left: -3px;
      }
      &Buttons {
        margin-top: 20px;

        text-align: right;
      }
      &Footer {
        margin-top: 20px;

        &Rounding {
          &Dropdown {
            top: 5px;

            margin: -10px 10px 0;

            &Value {
              min-width: 10px;

              padding-right: 15px;
            }
            &Arrow {
              right: -3px;
            }
          }
        }
      }
    }
    &Switch {
      margin-left: 15px;
      margin-top: 3px;
    }
    &Tabs {
      margin-top: -3px;
      margin-left: 15px;
    }
  }
  &Container {
    flex: 1 1 auto;

    display: flex;
  }
  &Wrapper {
    flex: 1;

    position: relative;
  }
}

.ContainerDisabled .Content {
  &:after {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    z-index: 10;
  }
  &Header {
    opacity: 0.5;
  }
}
