@import "./src/styles/global/_index.scss";
.Container {
  position: relative;
}

.LoaderContainer {
  position: absolute;
  top: 20px;
  right: 20px;
}

.List {
  &Container {
    position: absolute;

    border: 1px solid #dadada;
    border-top: none;
    background-color: #fff;

    z-index: 1001;
  }

  list-style: none;

  display: block;

  &Item {
    display: block;

    overflow: hidden;

    min-width: 120px;

    padding: 7px 7px;

    font-size: 14px;
    font-weight: 300;
    color: #222;
    line-height: 1.15;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include font-smoothing(off);

    &Focused {
      background-color: #eee;
    }
  }
}
