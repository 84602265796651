@import "./src/styles/global/_index.scss";
.Container {
  position: relative;

  padding: 30px 0;
}

.Category {
  position: relative;

  margin-top: 30px;

  &Title {
    font-size: 24px;
    font-weight: 300;
  }
}

.Search {
  overflow: hidden;
  position: absolute;
  top: -7px;
  right: 0;

  background-color: #fff;

  border-radius: 3px;

  &:before {
    position: absolute;
    top: 7px;
    right: 6px;

    font-size: 24px;
    color: $link-color;

    transform: rotate(90deg);
  }
  &NotEmpty:before {
    display: none;
  }
  &ResetBtn {
    display: none;
    position: absolute;
    top: 0;
    right: 0;

    padding: 6px 5px;

    font-size: 26px;
    color: $link-color;

    cursor: pointer;
  }
  &NotEmpty &ResetBtn {
    display: block;
  }
  &Field {
    width: 200px;

    padding: 8px 32px 8px 13px;

    border: none;
    background: none;

    font-size: 16px;
  }
}

.Filters {
  margin-top: 15px;

  &Item {
    display: inline;

    & + & {
      margin-left: 20px;
    }
    &Label {
      overflow: hidden;
      display: inline-block;

      margin-right: 5px;
      padding: 3px;

      font-size: 15px;
      font-weight: 300;
      color: #8b8b8b;
      @include font-smoothing(off);
    }
  }
}

.List {
  list-style: none;

  margin-left: -1.5%;

  @include clearfix;
}

.Study {
  float: left;

  position: relative;

  width: 23.5%;
  height: 296px;

  margin-top: 20px;
  margin-left: 1.5%;

  &Buttons {
    position: absolute;
    bottom: 15px;
    right: 17px;

    z-index: 1;

    &Item {
      &Wrapper {
        float: right;

        padding: 3px;

        opacity: 0.8;

        transition: opacity 0.2s ease;

        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }

      padding: 3px;

      border: 1px solid transparent;

      font-size: 18px;

      border-radius: 5px;

      transition: border 0.2s ease;

      &WrapperCopy:hover & {
        border-color: #888;

        color: #000;
      }
      &WrapperDelete:hover & {
        border-color: #f5838d;

        color: #f00;
      }
    }
  }

  &Link {
    box-sizing: border-box;

    display: block;

    width: 100%;
    height: 100%;

    padding: 18px 20px;

    border: 1px solid transparent;
    background-color: #fff;

    border-radius: 6px;

    &:hover {
      border-color: $link-color;

      box-shadow: 0 0 5px #ccc;
    }
  }

  &Name {
    overflow: hidden;

    font-size: 17px;
    font-weight: 400;
    color: #000;
    line-height: 1.35;
    @include font-smoothing(off);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &Image {
    &Container {
      position: relative;

      height: 170px;

      margin: 20px 0;
    }

    position: absolute;
    top: 50%;
    left: 50%;

    max-width: 100%;
    max-height: 100%;

    transform: translate(-50%, -50%);
  }

  &Date {
    position: absolute;
    bottom: 18px;
    left: 20px;
    right: 20px;

    font-size: 13px;
    color: #666;
  }

  &Tooltip {
    width: 400px;

    strong {
      display: block;
    }
  }
}

.ListSavedCubes .StudyImageContainer {
  height: 110px;

  margin: 55px 45px;
}

.ListSavedCubes .StudyCatalog + .StudyImageContainer {
  margin-top: 40px;
  margin-bottom: 40px;
}

.EmptyStudies {
  padding: 80px 0;

  font-size: 24px;
  color: #ddd;
  text-align: center;
}

.DropdownCurrentValue {
  min-width: 50px;
}
