@import "./src/styles/global/_index.scss";
.Container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  opacity: 1;

  background-color: rgba(0, 0, 0, 0.6);

  z-index: 1000;

  animation: ModalShowingAnimation 0.2s ease-in-out;

  @keyframes ModalShowingAnimation {
    0% {
      opacity: 0;
    }
    1% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}

.Parent {
  box-sizing: border-box;

  display: flex;
  flex-direction: column;

  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;

  width: 80%;
  height: 90%;

  background-color: #f2f2f2;;

  border-radius: 6px;

  box-shadow: 0 0 5px #222;

  transform: translate(-50%, -50%);
}

.Title {
  flex: 0 0 auto;

  overflow: hidden;

  padding: 15px 20px;

  background-color: #33828e;

  font-size: 18px;
  font-weight: 600;
  color: #fff;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.CloseBtn {
  position: absolute;
  top: 0;
  right: 0;

  padding: 8px;

  transition: opacity 0.2s ease;

  cursor: pointer;

  &Disabled {
    opacity: 0.5;
  }
  &:before {
    font-size: 28px;
    color: #33828e;
  }
  .Title ~ &:before {
    color: #fff;
  }
}

.Scrollbar {
  flex: 1 1 auto;

  display: flex;

  &Content {
    flex: 1;
  }
}

.Content {
  box-sizing: border-box;

  min-height: 100%;

  padding: 20px;
}

.Footer {
  flex: 0 0 auto;

  padding: 10px 20px;

  border-top: 1px solid #33828e;
}
