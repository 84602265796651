@import "./src/styles/global/_index.scss";
.Container {
  height: 100%;
}

.Control {
  display: flex;
  flex-direction: row;

  position: absolute;
  top: 10px;
  right: 30px;

  padding: 10px 20px;

  border: 1px solid #dadada;
  background-color: #fff;

  font-size: 15px;

  z-index: 1;

  &Label {
    display: inline-block;

    margin-right: 10px;
    padding-top: 6px;

    font-weight: 300;
    color: #8b8b8b;

    @include font-smoothing(off);
  }
  &Value {
    display: inline-block;

    margin-left: 3px;
    padding-top: 6px;
  }
  &Column {
    display: flex;
    flex-direction: row;

    height: 28px;

    & + & {
      margin-left: 30px;
    }
  }
  &Dropdown {
    margin-top: 2px;

    &Value {
      min-width: 25px;

      border: 1px solid #dadada;

      padding-top: 3px;
      padding-bottom: 3px;

      font-size: 15px;
      font-weight: 400;
    }
    &Opened &Value {
      border-color: transparent;
    }
    &Arrow {
      top: 0;
    }
    &Option {
      padding-left: 9px;
      padding-right: 9px;
    }
  }
}

.Scrollbar {
  &WithControlPanel {
    padding-top: 50px;
  }
}

.List {
  list-style: none;
}

.Item {
  & + & {
    margin-top: 50px;
  }
}

.Chart {
  &Container {
  }
  &Content {
    padding: 30px;

    &WithPan canvas {
      cursor: grab;

      &:active {
        cursor: grabbing;
      }
    }
  }
  &Title {
    margin-bottom: 25px;

    @include open-sans;
    font-size: 20px;
    color: #666;
    text-align: center;
  }
  &Legends {
    list-style: none;

    margin-bottom: 20px;

    text-align: center;

    &Item {
      position: relative;
      display: inline-block;

      margin: 0 20px;

      line-height: 25px;
      white-space: nowrap;

      user-select: none;
    }
    &ColorBox {
      float: left;

      display: inline-block;

      width: 30px;
      height: 12px;

      margin-top: 5px;
      margin-right: 8px;

      border-style: solid;

      transition: all 0.2s ease;
    }
    &Text {
      display: inline-block;

      white-space: normal;
    }
    &ColorPicker {
      position: absolute;
      top: 100%;
      left: 20px;

      z-index: 10;
    }
    &ItemDisabled &Text {
      text-decoration: line-through;
    }
  }
  &Wrapper {
    position: relative;

    height: calc(100vh - 400px);

    padding-top: 30px;
  }
  &Control {
    visibility: hidden;

    opacity: 0;

    position: absolute;
    top: 0;
    right: 0;

    transition: opacity 0.2s ease;

    @include clearfix;

    &Button {
      float: left;

      padding: 5px 10px;

      border: 1px solid #33828e;
      background-color: #fff;

      font-size: 14px;
      color: #33828e;

      border-radius: 3px;

      cursor: pointer;

      &:before {
        float: left;

        margin-right: 3px;
      }
      & + & {
        margin-left: 15px;
      }
    }
  }
  &Wrapper:hover &Control {
    visibility: visible;

    opacity: 1;
  }
}
