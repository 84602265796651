@import "./src/styles/global/_index.scss";
.Container {
  padding-top: 15px;
}

.Result {
  padding: 3px;

  border: 1px solid #e5e5e5;

  &ChangeBtn {
    float: right;

    margin: 0 1px 0 10px;
  }
  &Value {
    padding: 6px 9px;

    font-size: 14px;
  }
}

.SearchField {
  box-sizing: border-box;

  width: 100%;

  padding: 7px 10px;

  border: 1px solid #e5e5e5;
  background-color: #fff;
}

.List {
  &ScrollbarContainer {
    height: 150px;

    margin-top: 2px;

    border: 1px solid #d9d9d9;
  }

  list-style: none;

  &Item {
    display: block;

    padding: 7px 10px;

    &:hover,
    &Selected {
      background-color: #f5f5f5;
    }
  }
}

.NewFolderItem {
  &Btn {
    margin: -2px 10px -2px 0;
    padding: 1px 2px;

    border-radius: 2px;

    &:before {
      font-size: 11px;
    }
  }
}

.NewFolderForm {
  display: flex;
  flex-direction: row;

  &Label {
    flex: 0 auto;

    min-width: 80px;

    margin-right: 15px;
  }
  &Field {
    &Container {
      flex: 1;

      margin: -3px 0;
    }

    box-sizing: border-box;

    width: 100%;

    padding: 1px 5px;

    border: 1px solid #666;
    background-color: #fff;

    font-size: 12px;
  }
  &SubmitBtn {
    flex: 0 auto;

    min-width: 80px;

    margin: -3px 0 -3px 15px;
    padding: 2px 6px;

    border-radius: 0;
  }
}
