@import "./src/styles/global/_index.scss";
.Input {
  &Wrapper {
    display: inline-block;

    position: relative;

    padding: 0 5px;

    border: 1px solid #e5e5e5;
    background-color: #fff;

    &:before,
    &:after {
      content: '.';

      position: absolute;
      bottom: 5px;
      left: 27px;

      font-size: 14px;
      line-height: 1;

      pointer-events: none;
    }
    &:after {
      left: 50px;
    }
    &Disabled {
      background-color: #fafafa;

      color: #aaa;

      cursor: default;
    }
    &Opened {
      border-color: #222;
    }
  }

  box-sizing: border-box;

  padding: 2px 0;

  border: none;
  background: none;

  font-size: 14px;
  line-height: 20px;
  text-align: center;

  &Day,
  &Month {
    width: 24px;
  }
  &Year {
    width: 38px;
  }
}
