@import "./src/styles/global/_index.scss";
.Container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
}

.Content {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 300px;

  padding: 30px;

  border: 1px solid #dadada;
  background-color: #fff;

  border-radius: 3px;

  transform: translate(-50%, -50%);
}

.Question {
  font-size: 16px;
  color: #222;
  line-height: 1.35;
  text-align: center;
  word-wrap: break-word;
}

.Buttons {
  margin-top: 20px;

  text-align: center;
}
