@import "./src/styles/global/_index.scss";
.List {
  list-style: none;
}

.Item {
  overflow: hidden;
  position: relative;

  display: block;

  padding: 5px 0;
}

.Radio {
  position: absolute;
  bottom: 100%;
  right: 100%;

  opacity: 0;
}

.Label {
  position: relative;

  padding-left: 35px;

  font-size: 15px;
  color: #000;

  &:before {
    content: '';

    position: absolute;
    top: 3px;
    left: 0;

    width: 12px;
    height: 12px;

    border: 2px solid #00a6c0;

    border-radius: 50%;
  }
  &:after {
    content: '';

    display: none;

    position: absolute;
    top: 7px;
    left: 4px;

    width: 8px;
    height: 8px;

    background-color: #00a6c0;

    border-radius: 50%;
  }
}

.Radio:checked + .Label:after {
  display: block;
}
