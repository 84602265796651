@import "./src/styles/global/_index.scss";
.Container {
  position: relative;

  height: 24px;
}

.Circle {
  position: absolute;
  top: 8px;
  left: 50%;

  width: 8px;
  height: 8px;

  margin-left: -5px;

  background-color: #f48e28;

  border-radius: 50%;

  animation: oneLineLoaderAnimation 1s infinite;

  @keyframes oneLineLoaderAnimation {
    0% {
      transform: scale(0.1);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(0.1);
    }
  }

  &:nth-child(2) {
    margin-left: -20px;

    animation-delay: 0.33s;
  }
  &:nth-child(3) {
    margin-left: 10px;

    animation-delay: 0.66s;
  }
}
