@import "./src/styles/global/_index.scss";
.ContentAutoWidth {
  float: left;

  min-width: 100%;

  @include clearfix;
}

.TrackHidden {
  display: none;
}
