@import "./src/styles/global/_index.scss";
.Container {
  width: 600px;
}

.Content {
  display: flex;
  flex-direction: column;
}

.Form {
  display: flex;
  flex-direction: column;

  flex: 1;

  &Row {
    flex: 0 0 auto;

    &Content {
      display: flex;
      flex-direction: column;

      flex: 1 1 auto;

      height: auto;
    }
    & + & {
      margin-top: 15px;
    }
  }
  &Label {
    flex: 0 0 auto;

    display: block;

    font-size: 16px;
    color: #33828e;
  }
  &Field {
    box-sizing: border-box;

    width: 100%;

    margin-top: 8px;
    padding: 8px 13px;

    border: none;
    background-color: #fff;

    font-size: 16px;

    border-radius: 3px;

    &TextArea {
      flex: 1 1 auto;

      font-size: 14px;

      resize: none;
    }
  }
}

.Buttons {
  text-align: right;

  &Item {
    width: 105px;
  }
}
