@import "./src/styles/global/_index.scss";
.popup {
  display: flex;
  flex-direction: column;
  row-gap: 20px;

  .link {
    opacity: 1;

    &:hover {
      text-decoration: underline;
    }
  }
}
