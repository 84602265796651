@import "./src/styles/global/_index.scss";
.Container {
  display: inline-block;

  position: relative;

  &Opened {
    z-index: 1;
  }
}

.CurrentValue {
  display: inline-block;

  overflow: hidden;
  position: relative;

  min-width: 50px;
  max-width: 200px;

  padding: 3px;

  font-size: 15px;
  font-weight: 600;
  text-overflow: ellipsis;
  white-space: nowrap;

  user-select: none;

  &WithArrow {
    padding-right: 23px;
  }
  &Arrow {
    display: none;

    position: absolute;
    top: 1px;
    right: 2px;

    &:before {
      font-size: 20px;
      color: $link-color;
    }
  }
  &WithArrow &Arrow {
    display: block;
  }
}
.ContainerOpened .CurrentValue {
  z-index: 1;
}

.Options {
  position: absolute;
  top: -5px;
  left: -5px;

  padding-top: 29px;

  min-width: calc(100% + 8px);

  border: 1px solid #dadada;
  background-color: #fff;

  border-radius: 3px;

  &List {
    list-style: none;
  }
  &Item {
    display: block;

    overflow: hidden;

    padding: 7px 7px;

    font-size: 14px;
    font-weight: 300;
    color: #222;
    text-overflow: ellipsis;
    white-space: nowrap;
    @include font-smoothing(off);

    &Current,
    &:hover {
      background-color: #eee;
    }
  }
}
