@import "./src/styles/global/_index.scss";
.Container {
  position: absolute;
  left: 50%;
  top: 0;

  width: 30px;
  height: 14px;

  margin-left: -15px;

  user-select: none;

  cursor: ns-resize;

  &:before,
  &:after {
    content: '';

    position: absolute;
    top: 5px;
    left: 0;
    right: 0;

    height: 1px;

    background-color: #9b9b9b;
  }
  &:after {
    top: auto;
    bottom: 5px;
  }
}
