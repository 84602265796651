@import "./src/styles/global/_index.scss";
.List {
  list-style: none;

  overflow: hidden;

  display: inline-block;
}

.Item {
  float: left;

  padding: 5px 10px;

  border: 1px solid #00a6c0;

  font-size: 14px;
  color: $link-color;

  user-select: none;

  cursor: pointer;

  & + & {
    border-left: none;
  }
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
  &Disabled {
    border-color: #ddd;

    color: #aaa;

    cursor: default;
  }
  &Selected {
    background-color: #00a6c0;

    font-weight: 300;
    color: #fff;
    @include font-smoothing(off);

    cursor: default;
  }
}
