@import "./src/styles/global/_index.scss";
.Container {
  display: inline-block;

  position: relative;

  &Opened {
    z-index: 1;
  }
}

.Title {
  display: inline-block;

  overflow: hidden;
  position: relative;

  max-width: 200px;

  padding: 2px 3px;

  font-size: 15px;
  font-weight: 300;
  color: #000;
  @include font-smoothing(off);
  text-overflow: ellipsis;
  white-space: nowrap;

  user-select: none;

  cursor: pointer;

  &WithArrow {
    padding-right: 23px;
  }
  &Arrow {
    display: none;

    position: absolute;
    top: 1px;
    right: 2px;

    &:before {
      font-size: 20px;
      color: $link-color;
    }
  }
  &WithArrow &Arrow {
    display: block;
  }
}

.ContainerOpened .Title {
  color: $link-color;
}

.Content {
  display: none;

  position: absolute;
  top: 100%;
  left: 0;

  padding: 15px;

  min-width: 100%;

  border: 1px solid $link-color;
  background-color: #fff;

  border-radius: 5px;

  &Right {
    left: auto;
    right: 0;
  }
  &Fixed {
    position: fixed;

    min-width: 0;
  }
}

.ContainerOpened .Content {
  display: block;
}
