@import "./src/styles/global/_index.scss";
$static-table-padding: 19px;

.StaticTable {
  &Container {
    display: inline-block;

    min-width: calc(100% - #{($static-table-padding + 3px) * 2});
    min-height: calc(100% - #{($static-table-padding + 3px) * 2});

    margin: $static-table-padding;

    border: 1px solid #e6e6e6;

    border-radius: 5px;
  }

  width: 100%;
  height: 100%;

  border-collapse: collapse;

  &Row {
  }
}

.LazyTable {
  &Container {
    overflow: hidden;

    position: absolute;
    top: $static-table-padding;
    left: $static-table-padding;
    right: $static-table-padding;
    bottom: $static-table-padding;

    border: 1px solid #e6e6e6;

    border-radius: 5px;
  }
  &Content {
    overflow: hidden;

    position: relative;

    width: 100%;
    height: 100%;
  }
  &Box {
    position: absolute;
    top: 0;
    left: 0;

    &Top {
      right: 0;

      background-color: #f6f6f6;
    }
    &Left {
      bottom: 0;

      background-color: #f6f6f6;
    }
    &Center {
      right: 0;
      bottom: 0;
    }
  }

  overflow: hidden;
  position: relative;
}

.Cell {
  &Container {
    position: absolute;
  }

  box-sizing: border-box;

  overflow: hidden;

  height: 100%;

  border-bottom: 1px solid #f2f2f2;
  border-right: 1px solid #f2f2f2;

  &Head,
  &Filter,
  &AddDataBtn {
    border-color: #d6d6d6;
    background-color: #f6f6f6;
  }
  &Head &ContentContainer,
  &Filter &ContentContainer,
  &AddDataBtn &ContentContainer {
    background-color: inherit;
  }
  &Pointer {
    cursor: pointer;
  }
  &Active {
    background-color: #ccf6cd;
  }
  &Content {
    &Container {
      display: inline-block;
    }

    box-sizing: border-box;

    overflow: hidden;
    position: relative;

    min-height: 100%;

    padding: 10px 15px;

    font-size: 15px;
    font-weight: 300;
    @include font-smoothing(off);
    white-space: nowrap;
    text-overflow: ellipsis;

    &Empty {
      background-color: rgba(0, 0, 0, 0.02);
    }
  }
  &Sort,
  &Sort &ContentContainer {
    &:before {
      position: absolute;
      top: 9px;
      right: 1px;

      font-size: 20px;
      color: #bbb;

      z-index: 1;
    }
  }
  &Sort &ContentContainer {
    &:before {
      top: 4px;
    }
  }
  &SortActive,
  &SortActive &ContentContainer {
    &:before {
      color: #222;
    }
  }
}

.StaticTable .Cell {
  max-width: 300px;
  height: auto;

  &Filter {
    max-width: none;

    .Filter {
      width: auto;
    }
  }
  &AddDataBtn {
    width: 60px;
  }
  &Content {
    overflow: hidden;

    min-height: 38px;

    text-overflow: ellipsis;
  }
}

.LazyTable .CellContentContainer {
  display: block;

  height: 100%;
}

.AddDataBtn {
  overflow: hidden;

  min-width: 165px;

  padding: 10px 15px 10px 10px;

  font-size: 15px;
  font-weight: 300;
  color: #257985;
  @include font-smoothing(off);
  white-space: nowrap;

  &:before {
    float: left;

    margin-top: -2px;
    margin-right: 6px;

    font-size: 20px;
  }
}

.Filter {
  overflow: hidden;
  position: relative;

  width: 165px;

  padding: 10px 15px 10px 35px;

  font-size: 15px;
  font-weight: 300;
  color: #257985;
  @include font-smoothing(off);

  &:before {
    position: absolute;
    top: 8px;
    left: 10px;

    font-size: 20px;
  }
  &Line {
    & + &:before {
      content: ' / ';
    }
  }
}

.LazyTableContainer .Filter {
  box-sizing: border-box;

  width: 135px;
  min-width: inherit;

  display: inline-block;

  background-color: #f6f6f6;
}

.TooltipOverlay {
  &Row {
    & + & {
      margin-top: 10px;
    }
  }
  &Label {
    font-weight: 700;
  }
}
