@import "./src/styles/global/_index.scss";
.Container {
  overflow: hidden;
  position: relative;

  min-height: 20px;

  padding-top: 2px;
}

.Checkbox {
  position: absolute;
  bottom: 100%;
  right: 100%;

  opacity: 0;
}

.Label {
  position: relative;

  padding-left: 35px;

  font-size: 15px;
  color: #000;

  &Checkbox {
    position: absolute;
    top: -1px;
    left: 0;

    font-size: 24px;
    color: #00a6c0;

    &Checked {
      display: none;
    }
  }
}

.ContainerDisabled .LabelCheckbox {
  color: #9b9b9b;
}

.Checkbox:checked + .Label .LabelCheckbox {
  display: none;

  &Checked {
    display: block;
  }
}
