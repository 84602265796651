@import "./src/styles/global/_index.scss";
.Container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
}

.Content {
  position: absolute;
  top: 50%;
  left: 50%;

  width: 300px;

  padding: 30px;

  border: 1px solid #dadada;
  background-color: #fff;

  border-radius: 3px;

  transform: translate(-50%, -50%);
}

.Question {
  font-size: 16px;
  color: #222;
  line-height: 1.35;
  text-align: center;
}

.Fields {
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-top: 20px;

  &Separator {
    flex: 0 0 auto;

    padding: 0 10px;
  }
  &Item {
    &Container {
      flex: 1 1 auto;
    }

    box-sizing: border-box;

    width: 100%;

    padding: 8px 13px;

    border: 1px solid #e5e5e5;
    background: none;

    font-size: 16px;

    &Error {
      border-color: #e74c3c;
    }
  }
  &Measure {
    flex: 0 0 auto;

    padding-left: 10px;
  }
}

.Buttons {
  margin-top: 20px;

  text-align: center;

  &Item {
    width: 105px;
  }
}
