@charset "UTF-8";
.Container {
  display: inline; }

.Field {
  position: relative;
  display: inline;
  min-width: 20px;
  height: 18px;
  margin: 0 5px -2px;
  padding: 0 1px;
  border: none;
  border-bottom: 1px solid #222;
  background: none;
  font-size: 15px; }
  .FieldContainer {
    display: inline !important; }
  .FieldContainerInSelectionMode .Field {
    cursor: default; }

.Tags {
  display: inline; }
  .Tags:before {
    content: '('; }
  .Tags:after {
    content: ')'; }
  .TagsLayout {
    display: inline; }
  .TagsItem {
    display: inline; }
    .TagsItem:after {
      content: ' ';
      font-size: 1px; }
    .TagsItemContent {
      display: inline-block;
      margin: 0 3px;
      padding: 0 2px 0 3px;
      border: 1px solid #999;
      line-height: 18px;
      border-radius: 3px; }
    .TagsItemRemoveBtn {
      display: inline-block;
      margin-left: 3px;
      padding-left: 2px;
      border-left: 1px solid #999;
      cursor: pointer; }
      .TagsItemRemoveBtn:before {
        content: '×'; }

.Select {
  display: inline; }
  .SelectMulti:before {
    content: '('; }
  .SelectMulti:after {
    content: ')'; }
  .SelectFieldContailer {
    display: inline;
    position: relative; }
  .SelectFieldWithValue {
    min-width: 1px;
    margin-right: 0;
    padding-right: 0;
    border-bottom: none; }
  .SelectValue {
    display: inline;
    min-width: 20px;
    margin-left: -2px;
    margin-bottom: -1px;
    padding-left: 1px;
    border-bottom: 1px solid #222;
    cursor: text; }
  .SelectInSelectionMode .SelectField,
  .SelectInSelectionMode .SelectValue {
    cursor: default; }
  .SelectValues {
    list-style: none;
    display: inline; }
    .SelectValuesItem {
      display: inline; }
      .SelectValuesItem:before, .SelectValuesItem:after {
        content: ' ';
        font-size: 1px; }
      .SelectValuesItemContent {
        display: inline;
        margin: 0 3px;
        padding: 0 3px;
        border: 1px solid #999;
        white-space: nowrap;
        border-radius: 3px; }
    .SelectValuesLabel {
      padding-right: 3px; }
    .SelectValuesRemoveBtn {
      display: inline;
      height: 100%;
      padding-left: 2px;
      border-left: 1px solid #999;
      cursor: pointer; }
  .SelectOptions {
    position: fixed;
    margin-top: 5px;
    border: 1px solid #dadada;
    background-color: #fff; }
    .SelectOptionsList {
      list-style: none;
      display: block; }
    .SelectOptionsItem {
      display: block;
      overflow: hidden;
      min-width: 120px;
      padding: 7px 7px;
      font-size: 14px;
      font-weight: 300;
      color: #222;
      line-height: 1.15;
      text-overflow: ellipsis;
      white-space: nowrap;
      -webkit-font-smoothing: subpixel-antialiased;
      -moz-osx-font-smoothing: auto; }
      .SelectOptionsItemFocused {
        background-color: #eee; }
  .SelectLoader {
    display: inline-block;
    position: relative;
    width: 14px;
    height: 14px; }

.BetweenFields {
  display: inline; }
  .BetweenFields:before {
    content: '('; }
  .BetweenFields:after {
    content: ')'; }
