@import "./src/styles/global/_index.scss";
.Switch {
  display: inline-block;
  position: relative;

  width: 32px;
  height: 14px;

  margin: 0 5px;

  background-color: #c6c5c5;

  border-radius: 14px;

  transition: all 0.1s ease;

  user-select: none;

  cursor: pointer;

  &:before {
    content: '';

    position: absolute;
    top: -3px;
    left: -3px;

    width: 20px;
    height: 20px;

    background-color: #f1f1f1;

    border-radius: 20px;

    box-shadow: 0 2px 3px #ccc;

    transform: translateX(0);

    transition: all 0.1s ease;
  }
  &On {
    background-color: #7fcac3;

    &:before {
      background-color: #009688;

      transform: translateX(20px);
    }
  }
  &Disabled {
    background-color: #c6c5c5;

    opacity: 0.5;

    cursor: default;

    &:before {
      background-color: #f1f1f1;
    }
  }
}
