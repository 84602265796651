@import "./src/styles/global/_index.scss";
.Container {
  flex: 1 1 auto;

  display: flex;
  flex-direction: column;

  position: relative;

  padding-top: 10px;

  z-index: 2;
}

.Control {
  flex: 0 0 auto;

  height: 35px;
}

.AudienceSize {
  float: right;

  margin-top: 2px;
  margin-right: 15px;

  font-size: 15px;

  &Label {
    display: inline-block;

    margin-right: 3px;
    padding: 5px 0;

    font-weight: 300;
    color: #8b8b8b;

    &:after {
      content: ':';
    }
  }
  &Value {
    display: inline-block;
    position: relative;

    min-width: 15px;

    padding: 5px 0;

    color: #000;
    text-align: center;
  }
  &UpdateBtn {
    display: inline-block;

    width: 15px;

    margin-left: 10px;

    border: 1px solid $link-color;

    border-radius: 5px;

    text-align: center;

    cursor: pointer;

    &:before {
      font-size: 12px;
    }
  }
}

.Formula {
  box-sizing: border-box;

  min-height: 100%;

  font-size: 15px;
  line-height: 26px;

  user-select: none;

  &Scrollbar {
    position: relative;

    height: 100%;

    &Container {
      flex: 1 1 auto;

      position: relative;
    }
    &View {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }
  &Selection {
    border: 1px dashed #222;
  }
  &View {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    box-sizing: border-box;

    min-height: 100%;

    padding: 4px 15px 7px;
  }
  &AdditionalItem {
    box-sizing: border-box;

    position: absolute;
    top: 2px;
    left: 0;

    width: 15px;
    min-width: 15px;
    height: 32px;

    &Left {
      border-right: 2px solid transparent;

      z-index: 1;
    }
    &Right {
      left: 0;
      right: 0;
      bottom: 0;

      width: auto;
      height: auto;
    }
    &Over {
      border-right-color: #222;
    }
  }
  &Wrapper {
    display: inline;

    &:after {
      content: '';

      display: none;

      padding: 5px 0;

      border-right: 2px solid #222;
    }
  }
  &AdditionalItemOver + &Wrapper:after {
    display: inline;
  }
  &Content {
    display: inline;

    padding: 5px 0;

    &HasNot {
      &:after {
        content: '))';
      }
    }
    &Selected {
      background-color: #ffe35f;
    }
  }
  &NotOperator {
    display: none;

    &:before {
      content: '(';
    }
    &:after {
      content: ' (';
    }
  }
  &Group {
    display: inline;
  }
  &Parent {
    display: inline;

    position: relative;

    padding: 5px 0;

    &:before,
    &:after {
      display: inline;

      padding: 5px 1px;
    }
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
    &HasNot {
      &:after {
        content: '))';
      }
    }
    &Selected {
      &:before,
      &:after {
        background-color: rgb(153, 204, 255);
      }
    }
  }
  &Connector {
    display: inline;

    position: relative;

    padding: 5px 1px;

    &:hover {
      background-color: #feffd7;
    }
    &:before,
    &:after {
      content: ' ';

      font-size: 1px;
    }
    &Content {
      display: inline;

      &:before {
        content: '';

        display: none;

        position: absolute;
        top: 0;
        bottom: 0;

        width: 2px;

        background-color: #222;
      }
    }
    &CursorLeft &Content:before {
      display: block;

      left: 0;
    }
    &CursorRight &Content:before {
      display: block;

      right: 0;
    }
  }
  &Item {
    &Container {
      display: inline;
    }

    display: inline;

    position: relative;

    padding: 5px 1px;

    border-left: 2px solid transparent;
    border-right: 2px solid transparent;

    &:active {
      z-index: 1;
    }
    &:hover {
      background-color: #feffd7;
    }
    &CursorLeft {
      border-left-color: #222;
    }
    &CursorRight {
      border-right-color: #222;
    }
    &:before {
      content: '(';
    }
    &:after {
      content: ')';
    }
    &Invalid {
      background-color: #f5c6cb;

      box-shadow: 0 0 2px #f00;
    }
    &HasNot {
      &:after {
        content: '))';
      }
    }
    &Border {
      display: inline;
    }
    &EditBtn {
      position: relative;

      margin: 0 0 -9px 3px;
      padding: 3px 3px;

      border: 1px solid #e5e5e5;
      background-color: #fff;

      opacity: 0.3;

      line-height: 10px;

      transition: opacity 0.2s ease-in-out;

      border-radius: 3px;

      cursor: pointer;

      &:before {
        font-size: 16px;
      }
    }
    &Calendar {
      &Container {
        display: inline-block;

        line-height: 10px;
      }

      &Title {
        position: relative;

        margin: -1px 0 -8px 3px;
        padding: 5px 3px;

        border: 1px solid #e5e5e5;
        background-color: #fff;

        opacity: 0.3;

        transition: opacity 0.2s ease-in-out;

        border-radius: 3px;
      }
      &Opened &Title {
        border-radius: 3px 3px 0 0;
      }
      &Icon {
        &:before {
          font-size: 16px;
        }
      }
      &Content {
        margin-top: -1px;

        border: 1px solid #e5e5e5;

        border-radius: 0 3px 3px 3px;
      }
      &Row {
        overflow: hidden;

        & + & {
          margin-top: 15px;
        }
      }
      &Label {
        float: left;

        width: 100px;

        margin-top: 5px;
        margin-right: 15px;

        font-size: 13px;
        font-weight: 300;
        color: #8b8b8b;
        @include font-smoothing(off);
      }
      &Datepicker {
        overflow: hidden;
      }
    }
    &:hover &EditBtn,
    &:hover &CalendarTitle,
    &CalendarOpened &CalendarTitle,
    &CalendarNotEmpty &CalendarTitle {
      opacity: 1;
    }
  }
  &Parent > &NotOperator,
  &Item > &NotOperator {
    &:before {
      content: '';
    }
  }
  &ContentHasNot > &NotOperator,
  &ParentHasNot > &NotOperator,
  &ItemHasNot > &NotOperator {
    display: inline;
  }
  &ItemSelected,
  &ConnectorSelected,
  &GroupSelected &Parent,
  &GroupSelected &Item,
  &GroupSelected > &Connector {
    z-index: 1;

    &,
    &:hover {
      background-color: #ffe35f;
    }
  }
  &GroupSelectedFirst > &Connector:first-child,
  &ConnectorSelectedFirst {
    background-color: transparent !important;
  }
}
