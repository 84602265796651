@import "./src/styles/global/_index.scss";
.Container {
  position: absolute;

  padding-bottom: 3px;

  transform: translate(-50%, -100%);

  z-index: 100;

  pointer-events: none;

  &Bottom {
    transform: translate(-50%, 0);
  }
}

.Content {
  display: inline-block;

  position: relative;

  padding: 8px 10px;

  background-color: #373737;

  font-size: 12px;
  font-weight: 400;
  color: #fff;
  line-height: 1.5;
  text-align: left;
  text-decoration: none;
  @include font-smoothing(off);
  white-space: nowrap;

  border-radius: 6px;

  box-shadow: 0 0 4px rgba(0,0,0,.17);
}

.Arrow {
  position: absolute;
  top: 100%;
  left: 50%;

  border-style: solid;
  border-width: 5px 5px 0;
  border-color: transparent;
  border-top-color: #373737;

  transform: translateX(-50%);
}

.ContainerBottom .Arrow {
  top: auto;
  bottom: 100%;

  border-width: 0 5px 5px;
  border-top-color: transparent;
  border-bottom-color: #373737;
}

.ContainerFixed .Content {
  white-space: normal;
}
