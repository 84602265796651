@import "./src/styles/global/_index.scss";
.Container {
  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  min-height: 550px;
}

.Round {
  box-sizing: border-box;

  width: 500px;
  height: 500px;

  margin: 0 auto;
  padding-top: 100px;

  background-color: #fff;

  text-align: center;

  box-shadow: 0 0 20px #666;

  border-radius: 50%;
}

.Description {
  font-size: 18px;
  font-weight: 300;
  @include font-smoothing(off);
}

.Title {
  font-size: 200px;
  font-weight: 400;
  color: #f1682a;
}

.SubTitle {
  font-size: 38px;
  font-weight: 400;
  color: #33828e;
}
