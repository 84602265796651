@import "./src/styles/global/_index.scss";
.List {
  list-style: none;

  display: block;

  padding: 5px 15px;
}

.Item {
  float: left;

  padding: 2px 5px;

  border: 1px solid #cfcfcf;
  background-color: #fff;

  border-radius: 3px;

  cursor: pointer;

  &:hover {
    background-color: rgba(253, 236, 229, 0.4);
  }
  & + & {
    margin-left: 5px;
  }
}
