:global {
  [class^='icon-']:before,
  [class*=' icon-']:before {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'bifront' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-copy:before {
    content: "\e904";
  }
  .icon-bookmark:before {
    content: "\e902";
  }
  .icon-filter:before {
    content: "\e900";
  }
  .icon-plus-circle:before {
    content: "\e901";
  }
  .icon-delete:before {
    content: "\e903";
  }
  .icon-add:before {
    content: "\e145";
  }
  .icon-arrow-drop-down:before {
    content: "\e5c5";
  }
  .icon-arrow-drop-up:before {
    content: "\e5c7";
  }
  .icon-checkbox-checked:before {
    content: "\e834";
  }
  .icon-checkbox:before {
    content: "\e835";
  }
  .icon-close:before {
    content: "\e5cd";
  }
  .icon-edit:before {
    content: "\e254";
  }
  .icon-calendar:before {
    content: "\e916";
  }
  .icon-download:before {
    content: "\e884";
  }
  .icon-info:before {
    content: "\e88e";
  }
  .icon-more:before {
    content: "\e5d4";
  }
  .icon-account:before {
    content: "\e7ff";
  }
  .icon-refresh:before {
    content: "\e5d5";
  }
  .icon-search:before {
    content: "\e8b6";
  }
}
