@import "./src/styles/global/_index.scss";
.Container {
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  height: 100%;
  min-height: 400px;

  background-color: #e5ecf1;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: 1px;

    background-color: $link-color;
  }
}

.Form {
  width: 300px;

  padding: 40px 50px;

  border: 1px solid #cfd4d8;
  background-color: #f6fafb;

  border-radius: 6px;

  &WithErrors {
    animation: authErrorAnimation 0.5s ease;

    @keyframes authErrorAnimation {
      0%,
      100% {
        transform: translateX(0);
      }
      20%,
      60% {
        transform: translateX(10px);
      }
      40%,
      80% {
        transform: translateX(-10px);
      }
    }
  }
}

.Logo {
  &Wrapper {
    margin-bottom: 30px;

    text-align: center;
  }

  width: 200px;
}

.Row {
  & + & {
    margin-top: 10px;
  }
}

.Field {
  &Container {
    margin-top: 5px;
  }

  box-sizing: border-box;

  width: 100%;

  margin-top: 8px;
  padding: 8px 13px;

  border: 1px solid $link-color;
  background-color: #fff;

  font-size: 16px;

  border-radius: 3px;

  &:disabled {
    background-color: #f2f2f2;
  }
}

.FormWithErrors .Field {
  border-color: #e74c3c;
}

.SubmitBtn {
  width: 100%;

  margin-top: 20px;

  background-color: #fff;

  &:disabled {
    background-color: #f2f2f2;
  }
}

.LocaleSwitcher {
  position: absolute;
  top: 15px;
  right: 15px;
}
