@import "./src/styles/global/_index.scss";
.Container {
  position: fixed;
  left: 0;
  top: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;

  z-index: 100;
}
