$font-directory: './fonts';

/* BiFront icons */
@font-face {
  font-family: 'bifront';
  src:  url('#{$font-directory}/bifront/bifront.eot?abnhdh');
  src:  url('#{$font-directory}/bifront/bifront.eot?abnhdh#iefix') format('embedded-opentype'),
  url('#{$font-directory}/bifront/bifront.ttf?abnhdh') format('truetype'),
  url('#{$font-directory}/bifront/bifront.woff?abnhdh') format('woff'),
  url('#{$font-directory}/bifront/bifront.svg?abnhdh#bifront') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-directory}/open-sans/open-sans-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('#{$font-directory}/open-sans/open-sans-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-directory}/open-sans/open-sans-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-300.woff') format('woff'), /* Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-directory}/open-sans/open-sans-300.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-directory}/open-sans/open-sans-400.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('#{$font-directory}/open-sans/open-sans-400.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-directory}/open-sans/open-sans-400.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-400.woff') format('woff'), /* Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-400.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-directory}/open-sans/open-sans-400.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('#{$font-directory}/open-sans/open-sans-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('#{$font-directory}/open-sans/open-sans-600.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-directory}/open-sans/open-sans-600.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-600.woff') format('woff'), /* Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-600.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-directory}/open-sans/open-sans-600.svg#OpenSans') format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-directory}/open-sans/open-sans-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('#{$font-directory}/open-sans/open-sans-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-directory}/open-sans/open-sans-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-700.woff') format('woff'), /* Modern Browsers */
  url('#{$font-directory}/open-sans/open-sans-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-directory}/open-sans/open-sans-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}
