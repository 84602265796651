@import "./src/styles/global/_index.scss";
.Container {
  position: relative;

  padding-top: 15px;
}

.ScrollbarContent {
  min-height: 25px;

  padding: 4px 5px;

  border: 1px solid #e5e5e5;
}

.EmptyList {
  padding: 5px 5px;

  font-size: 14px;
  color: #e74c3c;
}

.Field {
  &Container {
    display: inline !important;
  }

  position: relative;
  display: inline-block;

  min-width: 20px;
  height: 18px;

  margin: 0 5px -2px;
  padding: 0 1px;

  border: none;
  border-bottom: 1px solid #222;
  background: none;

  font-size: 14px;
}

.Select {
  display: inline;

  &Field {
    &Contailer {
      display: inline;

      position: relative;
    }

    max-width: 508px;
    padding: 3px 0;

    &Starting {
      min-width: 508px;

      border-bottom-color: transparent;
    }
  }
  &Values {
    list-style: none;

    display: inline;

    &Item {
      display: inline-block;

      padding: 5px 0;

      &:before,
      &:after {
        content: ' ';

        font-size: 1px;
      }
      &Content {
        display: inline;

        margin: 0 3px;
        padding: 0 3px;

        border: 1px solid #999;

        white-space: nowrap;

        border-radius: 3px;
      }
    }
    &Label {
      padding-right: 3px;
    }
    &RemoveBtn {
      display: inline;

      height: 100%;

      padding-left: 2px;

      border-left: 1px solid #999;

      cursor: pointer;
    }
  }
  &Options {
    position: absolute;

    margin-top: 5px;

    border: 1px solid #dadada;
    background-color: #fff;

    &List {
      list-style: none;

      display: block;
    }
    &Item {
      display: block;

      overflow: hidden;

      min-width: 120px;

      padding: 7px 7px;

      font-size: 14px;
      font-weight: 300;
      color: #222;
      line-height: 1.15;
      text-overflow: ellipsis;
      white-space: nowrap;
      @include font-smoothing(off);

      &Focused {
        background-color: #eee;
      }
    }
  }
}
