@import "./src/styles/global/_index.scss";
.LocaleSwitcher {
  border: 1px solid #dadada;

  &CurrentValue {
    min-width: 0;

    font-weight: 300;
    @include font-smoothing(off);
  }
}
