@import "./src/styles/global/_index.scss";
.Container {
  flex: 0 0 auto;

  position: relative;

  height: $header-height;

  background-color: #fff;

  z-index: 101;
}

.Content {
  position: relative;
  display: flex;
  flex-direction: row;

  max-width: $content-max-width-without-offset;
  height: 100%;

  margin: 0 auto;
}

.Logo {
  flex: 0 0 auto;

  height: 100%;

  &Image {
    height: 40px;

    margin-top: 8px;
  }
}

.Account {
  flex: 0 0 auto;
  box-sizing: border-box;

  height: 100%;

  padding-top: 22px;

  &Link {
    position: relative;

    margin: 0 5px;
    padding-top: 2px;

    font-size: 14px;
    color: $link-color;

    cursor: pointer;

    &Profile {
      padding-left: 28px;
    }
    &:before {
      position: absolute;
      top: 0;
      left: 0;

      width: 19px;
      height: 19px;

      border: 2px solid $link-active-color;

      font-size: 19px;
      color: $link-active-color;
      text-align: center;
      line-height: 0.9 !important;

      border-radius: 50%;
    }
  }
  &Text {
    overflow: hidden;
    display: inline-block;

    max-width: 190px;

    margin: -4px 0;
    padding: 4px 0;

    text-overflow: ellipsis;
    line-height: 10px;
    white-space: nowrap;
  }
  //&Link:hover &Text {
  //  color: $link-active-color;
  //}
  & &Dropdown {
    float: right;

    margin-top: -6px;
    margin-left: 12px;
    margin-right: 12px;
  }
}

.Menu {
  flex: 1 1 auto;

  list-style: none;

  text-align: center;

  &Item {
    display: inline-block;

    margin: 0 10px;

    &Support {
      margin-right: 24px;
    }
  }
  &Link {
    display: inline-block;

    padding-top: 19px;
    padding-bottom: 17px;

    border-bottom: 3px solid transparent;

    font-size: 14px;
    font-weight: 600;

    &:hover {
      color: $link-active-color;
    }
    &Active {
      border-bottom-color: $link-active-color;
      color: $link-active-color;
    }
  }
}

.SkLink {
  align-self: center;
  padding: 16px;
}

.SkLogo {
  width: 100px;
  height: auto;
}
