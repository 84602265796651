@import "./src/styles/global/_index.scss";
.Container {
  width: 50%;
  height: 24px;

  margin: -3px 0 -2px;

  border: 1px solid #7f7f7f;
}

.Wrapper {
  height: 36px;
}

.Content {
  display: flex;
  flex-direction: row;
}

.Values {
  list-style: none;

  display: flex;
  flex-direction: row;

  padding: 2px 0 2px 2px;

  &Item {
    position: relative;

    padding: 2px 12px 2px 2px;

    border: 1px solid #c3c3c3;

    font-size: 14px;
    white-space: nowrap;

    &Close {
      position: absolute;
      top: 2px;
      right: 1px;

      color: #7f7f7f;

      cursor: pointer;

      &:before {
        display: inline-block;

        margin-top: 1px;

        font-size: 12px;
      }
    }
  }
}

.Field {
  &Container {
    flex: 1;
  }

  box-sizing: border-box;

  width: 100%;
  min-width: 185px;

  padding: 2px 4px;

  border: none;
  background: none;
}
