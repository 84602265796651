@import "./src/styles/global/_index.scss";
.Container {
  position: absolute;
  top: 0;
  left: 15px;
  right: 15px;
  bottom: 10px;

  padding: 5px;

  background-color: #feffd7;

  color: #888;

  &Over {
    background-color: #ffe35f;
  }
}
